<template>
  <div
    id="groupNewModel"
    class="page-container"
  >
    <!--phần header-->
    <button-all-header
      :content-btn-add="btnAddTitle"
      :hide-search="false"
      :hide-delete="false"
      :hide-export-file="false"
      :hide-import-file="false"
      :hide-dowload="false"
      @clickAdd="handleOpenModalCreate"
    />

    <div class="permission-content">
      <liqui-tree
        ref="tree"
        class="tree-mini"
        :data="dataListGroupNew"
        :options="getTreeOption"
      >
        <div
          slot-scope="{ node }"
          class="tree-text d-flex align-items-center"
          @mouseleave="mouseOutItem"
        >
          <template>
            <span
              @click.stop
            >
              <div class="d-flex align-items-center">
                <span class="flex items-center">
                  <span class="tree-text">{{ node.text }}</span>
                  <b-dropdown
                    v-if="treeOptions.type==='normal'"
                    class="node-action ml-1"
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                  >
                    <template v-slot:button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="text-body align-middle mr-25"
                      />
                    </template>
                    <b-dropdown-item
                      v-if="treeOptions.action&&treeOptions.action.includes('add')===true"
                      @click="handleOpenModal(node.id)"
                    >
                      <feather-icon
                        icon="PlusIcon"
                        class="mr-50"
                      />
                      <span>Thêm mới</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-if="treeOptions.action&&treeOptions.action.includes('edit')===true"
                      @click="handleOpenDetail(node.id)"
                    >
                      <feather-icon
                        icon="Edit2Icon"
                        class="mr-50"
                      />
                      <span>Chỉnh sửa  </span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-if="treeOptions.action&&treeOptions.action.includes('delete')===true"
                      @click="handleOpenModalDelete(node.id)"
                    >
                      <feather-icon
                        icon="TrashIcon"
                        class="mr-50"
                      />
                      <span>Xóa</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </span>
              </div>
            </span>
          </template>
        </div>
      </liqui-tree>
    </div>

    <!-- Modal tạo cơ cấu tổ chức mới -->
    <modal-create-group-new
      id-modal-create="modalCreate"
      :parent-id="parentId"
      :type="modalType"
      :data-group-new-id="dataGroupNewId"
      :group-category="groupCategory"
      @handleCreateGroupNew="handleCreateGroupNew"
    />

    <!-- Modal xóa-->
    <modal-delete-no-param
      id-modal-delete-no-param="idModalDeleteNoParam"
      :type="modalType"
      @accept="deleteAction"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BButton,
  BDropdown,
  BDropdownItem,
  VBTooltip,
  BBadge,
} from 'bootstrap-vue'

import LiquiTree from 'liquor-tree'
import Ripple from 'vue-ripple-directive'
import ButtonComponent from '@/components/button/ButtonComponent.vue'
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../constants/ConstantsApi'
// eslint-disable-next-line import/extensions
import ModalCreateGroupNew from '@/views/category-manager/group-new/pages/components/ModalCreateGroupNew'
// eslint-disable-next-line import/extensions
import ModalDeleteNoParam from '@/views/ndev-component/ModalDeleteNoParam'
import NCoreHelper from '@/views/ndev-component/constants/NCoreHelper'

export default {
  components: {
    ModalDeleteNoParam,
    ModalCreateGroupNew,
    BButton,
    LiquiTree,
    ButtonComponent,
    BDropdown,
    BDropdownItem,
    VBTooltip,
    BBadge,
    ButtonAllHeader,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    groupCategory: {
      type: Number,
      default: 1,
    },
    btnAddTitle: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      treeOptions: {
        type: 'normal',
        action: [
          'add',
          'add-from-file',
          'edit',
          'delete',
        ],
      },
      parentId: null,
      modalContent: '',
      deletedGroupNewId: '',
      modalType: '',
      idMinus: null,
      isMinus: false,
      dataGroupNewId: {},
      GroupNewId: null,
    }
  },
  computed: {
    ...mapGetters('groupNew', ['dataListGroupNew', 'dataGroupNewChildren']),
    getTreeOption() {
      return {
        // minFetchDelay: 200,
        fetchData: node => this.fetchNode(node),
        propertyNames: {
          text: 'name',
          children: 'childrens',
        },
      }
    },
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    ...mapActions('groupNew', [
      'doFetchListGroupNew',
      'doFetchGroupNewChildren',
    ]),

    // di chuyển chuột khỏi nhánh
    mouseOutItem(event) {
      const treeContent = event.target.closest('.tree-content')
      const els = treeContent.getElementsByClassName('show')
      if (els.length > 0) {
        for (let i = els.length - 1; i >= 0; i -= 1) {
          els[i].classList.remove('show')
        }
      }
    },

    // danh sách dữ liệu từ node được chọn đến gốc
    async fetchData() {
      const model = {
        groupId: this.groupCategory,
        idMinus: this.idMinus,
        isMinus: this.isMinus,
      }
      await this.doFetchListGroupNew(model)
      this.$nextTick(() => {
        this.dataListGroupNew.forEach(element => {
          element.isBatch = true
        })
        this.$refs.tree.setModel(this.dataListGroupNew)
      })
    },
    recursiveData(data) {
      data.state = {
        checked: false,
        expanded: false,
      }
      data.data = {
        selectOnly: true,
      }
      if (this.value.find(x => x === data.id)) {
        data.state.checked = true
      }
      if (data.childrens && data.childrens.length > 0) {
        data.state.expanded = true
        // data.isBatch = false
        data.childrens.forEach(element => {
          this.recursiveData(element)
        })
      }
    },

    // lấy dữ liệu node con cơ cấu tổ chức
    async fetchNode(node) {
      const model = {
        id: node.id,
        idMinus: this.idMinus,
        isMinus: this.isMinus,
      }
      await this.doFetchGroupNewChildren(model)
      this.dataGroupNewChildren.forEach(element => {
        element.isBatch = true
      })
      return new Promise(resolve => {
        resolve(this.dataGroupNewChildren)
      })
    },

    async handleOpenDetail(id) {
      this.modalType = 'edit'
      this.parentId = id
      this.GroupNewId = id
      this.$bvModal.show('modalCreate')
      const { data } = await axiosApiInstance.get(`${ConstantsApi.DETAIL_GROUP_NEW}${this.parentId}`)
      this.dataGroupNewId = data
    },

    // Mở modal
    async handleOpenModal(id) {
      this.parentId = id
      this.modalType = 'add'
      this.$bvModal.show('modalCreate')
      const { data } = await axiosApiInstance.get(`${ConstantsApi.DETAIL_GROUP_NEW}${this.parentId}`)
      this.dataGroupNewId = data
    },

    // Mở modal taoj mowis item
    handleOpenModalCreate() {
      this.modalType = 'add'
      this.$bvModal.show('modalCreate')
    },

    // Mở modal xóa
    handleOpenModalDelete(id) {
      this.deletedGroupNewId = id
      this.modalContent = this.$t('Bạn có muốn xóa không?')
      this.$bvModal.show('idModalDeleteNoParam')
    },

    // Action tạo mới và chỉnh sửa
    async handleCreateGroupNew(val, validate) {
      if (this.modalType === 'edit') {
        const payload = {
          ...val,
          id: this.GroupNewId,
        }
        const iResult = await NCoreHelper.excutePUT(this, ConstantsApi.EDIT_GROUP_NEW, payload, 'Cập nhật dữ liệu thành công')
        if (iResult) {
          await this.fetchData()
          this.$bvModal.hide('modalCreate')
          val.groupId = this.groupCategory
          val.name = ''
          val.parentId = ''
          val.description = ''
        }
      }
      if (this.modalType === 'add') {
        val.groupId = this.groupCategory
        const payload = {
          ...val,
        }
        const iResult = await NCoreHelper.excutePOST(this, ConstantsApi.CREATE_GROUP_NEW, payload, 'Thêm mới dữ liệu thành công')
        if (iResult) {
          await this.fetchData()
          this.$bvModal.hide('modalCreate')
          val.groupId = this.groupCategory
          val.name = ''
          val.parentId = ''
          val.description = ''
        }
      }
    },

    // Action xóa
    async deleteAction() {
      const modal = {
        ids: this.deletedGroupNewId,
      }
      const iResult = await NCoreHelper.excuteDELETE(this, ConstantsApi.DELETE_GROUP_NEW, { params: modal }, 'Xóa dữ liệu thành công')
      if (iResult) {
        await this.fetchData()
        this.$bvModal.hide('idModalDeleteNoParam')
      }
    },
  },
}
</script>

<style lang="scss" scope>
@import "@/assets/scss/tree/tree.scss";

#groupNewModel {
  .button-import {
    .input-file {
      display: none;
    }
  }

  .button-icon-custom {
    width: 38px;
    height: 38px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 11px;
  }

  .button-icon {
    .btn-component {
      width: 38px;
      height: 38px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 11px;
    }
  }

  .search-input {
    width: 400px;
  }

  .permission-content {
    border: 1px solid #A6A8B0;
    box-sizing: border-box;
    border-radius: 6px;
    height: 100%;

    .items-center {
      .dropdown-menu {
        margin-top: 0.2rem !important;
      }
    }
  }

  .btn-icon-file {
    width: 38px;
    height: 38px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 11px;

    label {
      margin-bottom: unset !important;
    }
  }
}
</style>
