<template>
  <b-modal
    :id="idModalCreate"
    size="sm"
    title="Thêm danh mục"
    cancel-title="Hủy bỏ"
    cancel-variant="outline-primary"
    ok-title="Lưu"
    centered
    @ok="handleCreateGroupNew"
    @hidden="resetState"
  >
    <b-form class="content-form">
      <b-form-group>
        <label>Tên {{ titleCategory }} <span class="required">(*)</span></label>
        <b-form-input
          id="InputHelp"
          v-model="queryBody.name"
          autofocus
          autocomplete="off"
        />
        <small
          v-if="!$v.queryBody.name.required"
          class="text-danger"
        >
          Không được bỏ trống</small>
        <small
          v-if="!$v.queryBody.name.maxLength"
          class="text-danger"
        >
          Tên dữ liệu có độ dài tối đa
          {{ $v.queryBody.name.$params.maxLength.min }} ký tự.</small>
      </b-form-group>
    </b-form>

    <b-form-group>
      <label>{{ titleCategory }} cha </label>
      <group-new-select
        v-model="queryBody.parentId"
        :parentId="parentId"
        :title-category="titleCategory"
        :no-title-category="titleCategory"
        :group-category="groupCategory"
      />
    </b-form-group>

    <b-form-group>
      <label>Mô tả</label>
      <b-form-textarea
        id="InputHelp"
        v-model="queryBody.description"
        rows="3"
        no-resize
      />
    </b-form-group>
  </b-modal>
</template>

<script>
// import { mapActions, mapGetters } from 'vuex'

import {
  BRow, BCol, BFormGroup, BModal, BFormInput, BForm, BFormTextarea,
} from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { maxLength, required } from 'vuelidate/lib/validators'
import GroupNewSelect from './GroupNewSelect.vue'
import NCoreHelper from '@/views/ndev-component/constants/NCoreHelper'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BModal,
    BFormInput,
    GroupNewSelect,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BFormTextarea,
  },
  props: {
    idModalCreate: {
      type: String,
      default: '',
    },
    parentId: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    dataGroupNewId: {
      type: Object,
      default: _ => {
      },
    },
    groupCategory: {
      type: Number,
      default: 1,
    },
    titleCategory: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      queryBody: {
        groupId: this.groupCategory,
        name: '',
        parentId: '',
        description: '',
      },
      dataDetailStrcut: null,
      customCode: {
        required: 'Tên ngành nghề kinh doanh',
      },
      customMessages: {
        required: `${this.titleCategory} cha`,
      },
    }
  },
  watch: {
    dataGroupNewId: {
      deep: true,
      handler(val) {
        if (val && this.type === 'edit') {
          this.queryBody = {
            groupId: this.groupCategory,
            name: this.dataGroupNewId.name,
            parentId: this.dataGroupNewId.parentId,
            description: this.dataGroupNewId.description,
          }
        }
        if (val && this.type === 'add') {
          this.queryBody = {
            level1: '',
            level2: '',
            level3: '',
            level4: '',
            level5: '',
            name: '',
            parentId: this.dataGroupNewId.id,
            description: '',
          }
        }
      },
    },
  },
  created() {
    this.callTitle()
  },
  validations: {
    queryBody: {
      name: {
        required,
        maxLength: maxLength(500),
      },
    },
  },
  methods: {
    handleCreateGroupNew(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$v.$touch()
      if (this.$v.$invalid) {
        NCoreHelper.showMessageError(this, 'Dữ liệu nhập chưa đầy đủ')
      } else {
        this.$emit('handleCreateGroupNew', this.queryBody, this.$refs.rule)
      }
    },
    resetState() {
      this.queryBody = {
        level1: '',
        level2: '',
        level3: '',
        level4: '',
        level5: '',
        name: '',
        parentId: '',
        description: '',
      }
    },
    callTitle() {
      let iTitle = ''
      switch (this.groupCategory) {
        case 1: {
          iTitle = 'nhóm tin tức'
          break
        }
        case 2: {
          iTitle = 'nhóm giới thiệu'
          break
        }
        case 5: {
          iTitle = 'nhóm thông số kỹ thuật'
          break
        }
        default: {
          iTitle = ''
          break
        }
      }
      this.titleCategory = iTitle
    },
  },
}
</script>
